import { InjectionToken } from '@angular/core';
import type { ValidatorFn } from '@angular/forms';
import type { AllowedDrupalType } from '@big-direkt/form/contracts';

export interface FormComponentsMapItem {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    import: () => Promise<any>;
    types: AllowedDrupalType[];
    validators?: ValidatorFn[];
    lazyValidators?: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        import: () => Promise<any>;
        method: string;
    }[];
}

export const FORM_COMPONENTS_MAP = new InjectionToken<FormComponentsMapItem[]>('form components map');
