import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, computed, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UserRepository, UserService } from '@big-direkt/state/user';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
    selector: 'big-ui-user-data',
    templateUrl: './user-data.component.html',
    imports: [TranslocoDirective, NgClass],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDataComponent {
    private readonly userRepository = inject(UserRepository);
    private readonly userService = inject(UserService);
    private readonly userProfile = toSignal(this.userService.getProfile());
    public readonly userData = computed(() => {
        const hasUserData = this.userRepository.isLoggedIn();
        this.hasUserData.emit(!!hasUserData);

        return hasUserData ? this.userProfile() : undefined;
    });

    public readonly idTranslationKey = computed(() => (this.userData()?.identifier.distributionPartnerNumber ? 'distributionPartnerId' : 'insuranceNumber'));
    public readonly id = computed(() => this.userData()?.identifier.distributionPartnerNumber ?? this.userData()?.identifier.healthInsuranceNumber ?? '');

    @Input({ required: true }) public viewMode?: 'breaking-left' | 'inline-center';
    @HostBinding('class') @Input() public classList = 'block';
    @Output() public readonly hasUserData = new EventEmitter<boolean>();
}
