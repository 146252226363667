@if (data(); as data) {
    <big-ui-alert
        *transloco="let t; prefix: 'errorHandlingAlert'"
        [classList]="'text-center border-2 rounded-md p-4! mb-20'"
        [type]="data.alertType"
        [title]="data?.title ?? t('default.title')">
        <div
            class="w-full"
            [innerHTML]="data?.content ?? t('default.content') | sanitize: securityContext"></div>
    </big-ui-alert>
}
