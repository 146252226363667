import { Injectable, inject } from '@angular/core';
import { EventBusService } from '@big-direkt/event-bus';
import { FormConfigElement } from '@big-direkt/form/contracts';
import { ComponentService } from '../component/component.service';
import { ConditionalFieldsService } from '../conditional-fields/conditional-fields.service';
import { FormRelationService } from '../form-relation/form-relation.service';
import { FormService } from '../form/form.service';
import { MultipleGroupService } from '../multiple-group/multiple-group.service';
import { PageNavigationService } from '../page-navigation/page-navigation.service';
import { TokenService } from '../token/token.service';
import { MultiplesService } from './../multiples/multiples.service';
import { RepeatingPageHandler } from './repeating-page.handler';
@Injectable({
    providedIn: 'root',
})
export class RepeatingPageService {
    public readonly componentService = inject(ComponentService);
    private readonly multiGroupService = inject(MultipleGroupService);
    private readonly multiplesService = inject(MultiplesService);
    private readonly conditionalFieldsService = inject(ConditionalFieldsService);
    public readonly formRelationService = inject(FormRelationService);
    private readonly tokenService = inject(TokenService);
    private readonly formService = inject(FormService);
    private readonly pageNavigationService = inject(PageNavigationService);
    private readonly eventBus = inject(EventBusService);

    private handlers: RepeatingPageHandler[] = [];
    public registerRepeatingPages(settings: FormConfigElement): void {
        if (settings.type === 'big_webform_page_repeating') {
            this.registerRepeatingPage(settings);
        }
        settings.children.forEach(x => {
            this.registerRepeatingPages(x);
        });
    }

    public reset(): void {
        this.handlers.forEach(x => {
            x.destroy();
        });
        this.handlers = [];
    }

    private registerRepeatingPage(settings: FormConfigElement): void {
        this.handlers.push(
            new RepeatingPageHandler(
                settings,
                this.multiGroupService,
                this.tokenService,
                this.formService,
                this.pageNavigationService,
                this.conditionalFieldsService,
                this.formRelationService,
                this.componentService,
                this.multiplesService,
                this.eventBus,
            ),
        );
    }
}
