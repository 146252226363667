import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { FORM_COMPONENTS_MAP } from '../../../form-components-map';

export const provideFormViewCheckbox = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: FORM_COMPONENTS_MAP,
            useValue: {
                import: async () => import('./checkbox.component'),
                types: ['checkbox'],
            },
            multi: true,
        },
    ]);
