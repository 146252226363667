import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { FORM_COMPONENTS_MAP } from '../../../form-components-map';

export const provideFormViewItemCollection = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: FORM_COMPONENTS_MAP,
            useValue: {
                import: async () => import('./item-collection.component'),
                types: [
                    'webform_personal_data_element',
                    'webform_current_bank_data_element',
                ],
            },
            multi: true,
        },
    ]);
