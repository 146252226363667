import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { FORM_COMPONENTS_MAP } from '../../../form-components-map';

export const provideFormViewElementsHolder = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: FORM_COMPONENTS_MAP,
            useValue: {
                import: async () => import('./elements-holder.component'),
                types: [
                    'webform_custom_composite',
                    'webform_contact',
                    'webform_entity_checkboxes',
                    'webform_entity_radios',
                    'webform_entity_select',
                    'webform_link',
                ],
            },
            multi: true,
        },
    ]);
