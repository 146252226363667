/* eslint-disable @angular-eslint/template/cyclomatic-complexity, @angular-eslint/template/no-call-expression */
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MobileAppRepository } from '@big-direkt/state/mobile-app';
import { UiUserDataComponent } from '@big-direkt/ui/user-data';
import { FeatureFlagsService } from '@big-direkt/utils/environment';
import { TranslocoModule } from '@jsverse/transloco';
import { WizardBaseComponent } from '../../../base-components/wizard-base/wizard-base.component';
import { ReplaceTokenRefPipe } from '../../../pipes/replace-token-ref/replace-token-ref.pipe';
import { WizardVisiblePipe } from '../../../pipes/wizard-visible/wizard-visible.pipe';
import { PageHierarchyFormNode } from '../../../services/page-navigation/page-hierarchy-node';
import { PageNavigationService } from '../../../services/page-navigation/page-navigation.service';
import { WizardStepItemComponent } from '../wizard-step-item/wizard-step-item.component';

@Component({
    selector: 'big-form-wizard',
    templateUrl: './wizard.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass, ReplaceTokenRefPipe, UiUserDataComponent, TranslocoModule, WizardStepItemComponent, WizardVisiblePipe],
})
export class WizardComponent extends WizardBaseComponent {
    public override readonly pageNavigationService = inject(PageNavigationService);
    private readonly mobileAppRepository = inject(MobileAppRepository);
    public readonly featureFlagsService = inject(FeatureFlagsService);

    public readonly pages = signal<PageHierarchyFormNode[]>(this.pageNavigationService.pages);

    public get isLastPageActive(): boolean {
        return this.currentPage?.settings.name === 'end_page';
    }

    public constructor() {
        super();

        this.eventBus
            .on('form_repeating_pages_updated')
            .pipe(takeUntilDestroyed())
            .subscribe(() => {
                // we could get rid of this as soon as the pageNavigationService.pages is an signal
                this.pages.set([...this.pageNavigationService.pages]);
            });

        this.eventBus
            .on('forms_field_change_event')
            .pipe(takeUntilDestroyed())
            .subscribe(() => {
                this.pages.set([...this.pageNavigationService.pages]);
            });
    }

    public goToPage(page: PageHierarchyFormNode): void {
        // REFACTOR: Same scrolling behaviour happens in FormViewComponent. Maybe we can move the scrolling into PageNavigationService?
        const scrollTarget = this.mobileAppRepository.isEmbeddedInMobileApp() ? 'body' : 'big-form-holder';
        this.scrollService.scroll(scrollTarget);

        this.pageNavigationService.gotoPage(page);
    }
}
