import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { FORM_COMPONENTS_MAP } from '../../../form-components-map';

export const provideFormViewSearchableSelect = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: FORM_COMPONENTS_MAP,
            useValue: {
                import: async () => import('./searchable-select.component'),
                types: ['big_webform_searchable_select'],
            },
            multi: true,
        },
    ]);
