<div
    class="flex items-center justify-center text-center rounded-full w-10 h-10 transition-[background-color]"
    [ngClass]="{
        'bg-primary dark:bg-redesign-primary-400 text-white fill-white': active,
        'bg-alt': !active,
    }">
    @if (page && !isLast) {
        {{ page }}
    } @else {
        <big-icon
            [icon]="iconOk"
            [size]="'w-4'"
            class="dark:fill-white" />
    }
</div>
