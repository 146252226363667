import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { FORM_COMPONENTS_MAP } from '../../../form-components-map';

export const provideFormViewPage = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: FORM_COMPONENTS_MAP,
            useValue: {
                import: async () => import('./page.component'),
                types: [
                    'big_webform_page',
                    'big_webform_repeated_page',

                    // TODO: this is not defined in the old drupal map but how is this working?
                    // is it needed anymore, old config old tests?
                    'big_webform_page_repeating',
                ],
            },
            multi: true,
        },
    ]);

