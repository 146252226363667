import { Component } from '@angular/core';
import { UiSpinnerModule } from '@big-direkt/ui/spinner';

@Component({
    selector: 'big-form-initial-loading',
    templateUrl: './initial-loading.component.html',
    imports: [UiSpinnerModule],
})
export class InitialLoadingComponent {
}
