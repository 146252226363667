import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { FORM_COMPONENTS_MAP } from '../../../form-components-map';
import { CUSTOM_FORM_CONTROL_VALIDATOR_TOKEN } from '../../../interfaces/custom-form-control-validator.token';
import { passwordConfirmValidatorFactory } from '../../factories/password-confirm-validator.factory';

export const provideFormViewPasswordConfirm = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: FORM_COMPONENTS_MAP,
            useValue: {
                import: async () => import('./password-confirm.component'),
                types: ['password_confirm'],
            },
            multi: true,
        },
        {
            provide: CUSTOM_FORM_CONTROL_VALIDATOR_TOKEN,
            useFactory: passwordConfirmValidatorFactory,
            multi: true,
        },
    ]);
