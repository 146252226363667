<ng-template
    #itemTemplate
    let-control="control">
    <ng-container *transloco="let t; prefix: 'ftbStatePreview'">
        @if (control.controls) {
            @for (item of control.controls | keyvalue; track item.key) {
                <details>
                    <summary>
                        <span
                            class="inline-block w-3 h-3 rounded-lg"
                            [ngClass]="{ 'bg-orange-500': item.value.invalid, 'bg-green-500': item.value.valid }"
                            [title]="item.value.status"></span>
                        {{ item.key }}{{ item.value.isRequired ? '&nbsp;' + t('required') : '' }}
                    </summary>
                    <div class="pl-2 ml-2 border-l-2 border-gray-100">
                        <ng-template
                            [ngTemplateOutlet]="itemTemplate"
                            [ngTemplateOutletContext]="{ control: item.value }"></ng-template>
                    </div>
                </details>
            }
        } @else {
            <div class="px-1 bg-white dark:bg-dark-contrast! border border-gray-500">{{ control.value || ' ' }}</div>
        }
    </ng-container>
</ng-template>

<div
    class="absolute top-0 bottom-0 left-0 z-(--z-modal) w-0 overflow-hidden transition-[width] ease-in-out bg-gray-400 dark:bg-redesign-primary-800!"
    [ngClass]="{ 'w-min': showFormState }">
    <div class="p-4 whitespace-pre">
        <div
            class="block w-3 h-3 rounded-lg"
            [ngClass]="{ 'bg-orange-500': control.invalid, 'bg-green-500': control.valid }"
            [title]="control.status"></div>
        <ng-template
            [ngTemplateOutlet]="itemTemplate"
            [ngTemplateOutletContext]="{ control: control }"></ng-template>
    </div>
</div>
<button
    class="absolute left-0 h-10 p-2 text-white z-(--z-modal) top-1/2 bg-primary"
    (click)="showFormState = !showFormState"
    [textContent]="showFormState ? '<' : '>'">
    x
</button>
