import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { FORM_COMPONENTS_MAP } from '../../../form-components-map';
import { CUSTOM_FORM_CONTROL_VALIDATOR_TOKEN } from '../../../interfaces/custom-form-control-validator.token';
import { currencyFieldValidatorFactory } from '../../factories/currency-field-validator.factory';

export const provideFormViewCurrencyInput = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: FORM_COMPONENTS_MAP,
            useValue: {
                import: async () => import('./currency-input.component'),
                types: ['big_webform_currency'],
            },
            multi: true,
        },
        {
            provide: CUSTOM_FORM_CONTROL_VALIDATOR_TOKEN,
            useFactory: currencyFieldValidatorFactory,
            multi: true,
        },
    ]);
