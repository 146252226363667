@if (userData()) {
    <div
        *transloco="let t; prefix: 'uiUserData'"
        class="flex flex-col"
        [ngClass]="{
            'items-start justify-start': viewMode === 'breaking-left',
            'text-center justify-center md:flex-row': viewMode === 'inline-center',
        }">
        <span class="p-0 m-0 font-semibold text-primary dark:text-white">
            {{ userData()?.name?.fullTitle || '' }}
        </span>
        <span
            class="hidden mx-4 dark:text-primary-50"
            [ngClass]="{ 'md:block': viewMode === 'inline-center' }">
            |
        </span>
        <span class="p-0 m-0 dark:text-primary-50">
            @if (viewMode === 'breaking-left') {
                {{ t(idTranslationKey()) }}:
                {{ id() }}
            } @else {
                <span class="block min-[320px]:inline">
                    {{ t(idTranslationKey()) }}:
                </span>
                <span class="block min-[320px]:inline">
                    {{ id() }}
                </span>
            }
        </span>
    </div>
}
