import { inject, Injectable } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { AllowedDrupalType, FormConfigElement } from '@big-direkt/form/contracts';
import { combineLatest, from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FORM_COMPONENTS_MAP } from '../../form-components-map';
import { ComponentMap, ExtendedMapConfig } from '../../utilities/component-map/component-map';
import { DrupalWebformService } from '../drupal-webform/drupal-webform.service';

type ComponentToImport = ExtendedMapConfig & {
    types: AllowedDrupalType[];
} | undefined;

@Injectable({
    providedIn: 'any',
})
export class LazyFormComponentsLoaderService {
    private readonly drupalWebformService = inject(DrupalWebformService);
    private readonly formComponentsMap = inject(FORM_COMPONENTS_MAP);

    public load(form: FormConfigElement): Observable<FormConfigElement> {
        const componentTypes = form.getUniqueTypes();

        // TODO: find a better solution, but why is this not part of the config in the first place
        // maybe we need another property linkedComponents in the formComponentsMap
        if (componentTypes.find(componentType => componentType === 'big_webform_multiple')) {
            componentTypes.push('webform_multiple_item');
        }

        const componentsToImport: Observable<ComponentToImport | undefined>[] = [];

        for (const componentType of componentTypes) {
            const componentToImport = this.formComponentsMap.find(mapItem => mapItem.types.includes(componentType));

            if (!componentToImport) {
                console.warn('not able to find', componentType, 'in FORM_COMPONENTS_MAP');

                continue;
            }

            const componentLazyValidators = componentToImport.lazyValidators;
            const componentValidatorImportObservables = [];

            if (componentLazyValidators?.length) {
                for (const componentLazyValidator of componentLazyValidators) {
                    componentValidatorImportObservables.push(from(componentLazyValidator.import().then(v => ({
                        class: v,
                        method: componentLazyValidator.method,
                    }))));
                }
            }

            const loadValidators = componentValidatorImportObservables.length > 0
                ? combineLatest(componentValidatorImportObservables)
                : of([]);

            const componentImportObservable = loadValidators.pipe(
                switchMap(loadedValidators => {
                    const lazyValidators: ValidatorFn[] = [];

                    if (loadedValidators.length > 0) {
                        for (const loadedValidator of loadedValidators) {
                            const className = Object.keys(loadedValidator.class).at(0);

                            if (!className || !loadedValidator.method) {
                                console.warn('lazy validator for', componentType, 'class or method', 'is missing in config');

                                continue;
                            }

                            const validatorWithMethod = loadedValidator.class[className][loadedValidator.method];

                            if (!validatorWithMethod) {
                                console.warn('lazy validator for', componentType, 'method', loadedValidator.method, 'is missing in class', className);

                                continue;
                            }

                            lazyValidators.push(validatorWithMethod);
                        }
                    }

                    return from(componentToImport.import().then(m => {
                        // eslint-disable-next-line sonarjs/no-nested-functions
                        const component = Object.keys(m).find(key => key.endsWith('Component'));

                        if (!component) {
                            // eslint-disable-next-line no-console
                            console.error('Could not find a component key in component for form view', componentType);

                            return void 0;
                        }

                        return {
                            types: componentToImport.types,
                            component: m[component],
                            validators: [
                                ...componentToImport.validators ?? [],
                                ...lazyValidators,
                            ],
                        };
                    }))
                }),
            );

            componentsToImport.push(componentImportObservable);
        }

        if (componentsToImport.length === 0) {
            return of(form);
        }

        return combineLatest(componentsToImport).pipe(
            map(importedComponents => {
                this.addToComponentMap(importedComponents)

                return form;
            }),
        );
    }

    private addToComponentMap(importedComponents: ComponentToImport[]): void {
        const componentMap: ComponentMap = {};

        for (const importedComponent of importedComponents) {
            if (!importedComponent) {
                continue;
            }

            for (const componentType of importedComponent.types) {
                componentMap[componentType] = {
                    component: importedComponent.component,
                    validators: importedComponent.validators,
                };
            }
        }

        this.drupalWebformService.addToComponentMap(componentMap);
    }
}
