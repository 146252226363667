import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { FORM_COMPONENTS_MAP } from '../../../form-components-map';
import { CUSTOM_FORM_CONTROL_VALIDATOR_TOKEN } from '../../../interfaces/custom-form-control-validator.token';
import { timeRangeChildValidatorFactory } from '../../factories/time-range-child-validator.factory';

export const provideFormViewTimeRange = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: FORM_COMPONENTS_MAP,
            useValue: {
                import: async () => import('./time-range.component'),
                types: ['big_webform_time_range'],
            },
            multi: true,
        },
        {
            provide: CUSTOM_FORM_CONTROL_VALIDATOR_TOKEN,
            useFactory: timeRangeChildValidatorFactory,
            multi: true,
        },
    ]);
