import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { FORM_COMPONENTS_MAP } from '../../../form-components-map';

export const provideFormViewFileUpload = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: FORM_COMPONENTS_MAP,
            useValue: {
                import: async () => import('./file-upload.component'),
                types: [
                    'big_webform_transactional_file',
                    'big_webform_d3_upload',
                    'managed_file',
                    'webform_document_file',
                    'webform_image_file',
                    'webform_audio_file',
                    'webform_video_file',
                ],
            },
            multi: true,
        },
    ]);
