import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable, makeStateKey, PLATFORM_ID, TransferState } from '@angular/core';
import { EnvironmentService, ErrorHandlingService, handleServerState } from '@big-direkt/utils/environment';
import { type Link } from '@big-direkt/utils/shared';
import { catchError, firstValueFrom, type Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { type Document } from './models/jsonapi/document';
import { type ResourceTypes } from './models/resource.types';
import { ResourceInclude } from './resource-include';
import { ResourcePath } from './resource-path';
import { ResponseMapperService } from './response-mapper/response-mapper.service';

@Injectable({
    providedIn: 'root',
})
export class JsonApiClientService {
    private readonly httpClient = inject(HttpClient);
    private readonly environment = inject(EnvironmentService);
    private readonly errorHandling = inject(ErrorHandlingService);
    private readonly responseMapper = inject(ResponseMapperService);
    private readonly transferState = inject(TransferState);
    private readonly platformId = inject(PLATFORM_ID);

    public load<T>(uuid: string, type: ResourceTypes, params?: Record<string, number | string>): Observable<T> {
        const stateKey = makeStateKey<Document>(`${uuid}-${type}`);
        const path: string | undefined = this.getResourcePath(type);
        const include: string[] | undefined = ResourceInclude[type];
        const httpContext: { url: string; params?: Record<string, string[] | string> } = this.formatUrl(`${path ?? ''}/${uuid}`, include, undefined, params);

        return this.httpClient.get<Document>(httpContext.url, { params: httpContext.params }).pipe(
            handleServerState<Document>(this.transferState, stateKey, isPlatformServer(this.platformId)),
            catchError((err: Error) => this.errorHandling.catchError(err)),
            map((document: Document): T => this.responseMapper.mapSingle(document) as T),
            shareReplay({ refCount: true }),
        );
    }

    public find<T>(type: ResourceTypes, filter?: Record<string, string>, params?: Record<string, string>): Observable<T[]> {
        const path: string | undefined = this.getResourcePath(type);
        const include: string[] | undefined = ResourceInclude[type];

        const httpContext: { url: string; params?: Record<string, string[] | string> } = this.formatUrl(path, include, filter, params);

        return this.httpClient
            .get<Document>(httpContext.url, { params: httpContext.params })
            .pipe(map((document: Document): T[] => this.responseMapper.mapMultiple(document)));
    }

    public async findWithNext<T>(type: ResourceTypes, params?: Record<string, number | string>): Promise<T[]> {
        const path: string | undefined = this.getResourcePath(type);
        const include: string[] | undefined = ResourceInclude[type];
        const httpContext: { url: string; params?: Record<string, string[] | string> } = this.formatUrl(path, include, undefined, params);
        const doc: Document = await firstValueFrom(this.httpClient.get<Document>(httpContext.url, { params: httpContext.params }));

        return this.joinWithNext<T>(doc);
    }

    private async joinWithNext<T>(doc: Document): Promise<T[]> {
        // eslint-disable-next-line no-extra-parens
        const href: string | undefined = (doc.links?.next as Link | undefined)?.href;
        const items: T[] = this.responseMapper.mapMultiple<T>(doc);
        if (href) {
            const nextDoc: Document = await firstValueFrom(this.httpClient.get<Document>(href));

            return [...items, ...(await this.joinWithNext<T>(nextDoc))];
        }

        return items;
    }

    private getResourcePath(type: ResourceTypes): string | undefined {
        if (type in ResourcePath) {
            return ResourcePath[type];
        }

        throw new Error(`Cannot load resource. Path for resource type '${type}' is not defined`);
    }

    private formatUrl(
        path: string | undefined,
        include?: string[],
        filter?: Record<string, string>,
        params?: Record<string, number | string>,
    ): { url: string; params?: Record<string, string[] | string> } {
        if (path === undefined) {
            return { url: '' };
        }

        if (path.startsWith('/')) {
            // eslint-disable-next-line no-param-reassign
            path = path.slice(1);
        }

        const fullPath = `${this.environment.baseHref}${this.environment.jsonapiPath}/${path}`;
        const queryParams: Record<string, string[] | string> = {};

        if (filter) {
            Object.keys(filter).forEach((key: string): void => {
                queryParams[`filter[${key}]`] = filter[key];
            });
        }

        if (params) {
            Object.keys(params).forEach((key: string): void => {
                queryParams[key] = params[key] as string;
            });
        }

        if (include) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            queryParams['include'] = include.toString();
        }

        return { url: new URL(fullPath, this.environment.baseHref).toString(), params: queryParams };
    }
}
