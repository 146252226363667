import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { FORM_COMPONENTS_MAP } from '../../../form-components-map';
import { CUSTOM_FORM_CONTROL_VALIDATOR_TOKEN } from '../../../interfaces/custom-form-control-validator.token';
import { numberFieldValidatorFactory } from '../../factories/number-field-validator.factory';

export const provideFormViewNumberField = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: FORM_COMPONENTS_MAP,
            useValue: {
                import: async () => import('./numberfield.component'),
                types: ['number'],
            },
            multi: true,
        },
        {
            provide: CUSTOM_FORM_CONTROL_VALIDATOR_TOKEN,
            useFactory: numberFieldValidatorFactory,
            multi: true,
        },
    ]);
