import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { FORM_COMPONENTS_MAP } from '../../../form-components-map';

export const provideFormViewComposite = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: FORM_COMPONENTS_MAP,
            useValue: {
                import: async () => import('./composite.component'),
                types: [
                    'big_webform_bank_data',
                    'big_webform_contact_data',
                ],
            },
            multi: true,
        },
    ]);
