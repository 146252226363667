@let accordionBlock = accordion();

@if (faqNodeId) {
    @if (isLoading()) {
        <big-ui-spinner spinnerClass="inline-flex items-center justify-center py-12">Lade häufig gestellte Fragen...</big-ui-spinner>
    } @else if (hasErrors()) {
        <p class="text-center">Fehler beim Laden der FAQ-Elemente</p>
    } @else if (accordionBlock) {
        @if (accordionBlock.title) {
            <h2 class="text-center break-long-words">
                {{ accordionBlock.title }}
            </h2>
        }
        <big-ui-accordion>
            @for (item of accordionBlock.items; track index; let index = $index) {
                <big-ui-accordion-item
                    [index]="index"
                    [title]="item.title">
                    <big-paragraphs-holder [data]="item.paragraphs" />
                </big-ui-accordion-item>
            }
        </big-ui-accordion>
    }
}
