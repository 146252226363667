import { type EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { Validators } from '@angular/forms';
import { FORM_COMPONENTS_MAP } from '../../../form-components-map';
import { CUSTOM_FORM_CONTROL_VALIDATOR_TOKEN } from '../../../interfaces/custom-form-control-validator.token';
import { textFieldValidatorFactory } from '../../factories/text-field-validator.factory';

export const provideFormViewTextfield = (): EnvironmentProviders =>
    makeEnvironmentProviders([
        {
            provide: FORM_COMPONENTS_MAP,
            useValue: {
                import: async () => import('./textfield.component'),
                types: [
                    'big_webform_bic',
                    'textfield',
                    'url',
                ],
            },
            multi: true,
        },
        {
            provide: FORM_COMPONENTS_MAP,
            useValue: {
                import: async () => import('./textfield.component'),
                types: ['big_webform_iban'],
                lazyValidators: [{
                    import: async () => import('./validators/iban/iban'),
                    method: 'validateFormControl',
                }],
            },
            multi: true,
        },
        {
            provide: FORM_COMPONENTS_MAP,
            useValue: {
                import: async () => import('./textfield.component'),
                types: [
                    'big_webform_telephone',
                    'tel',
                ],
                lazyValidators: [{
                    import: async () => import('./validators/lib-phone-number/lib-phone-number'),
                    method: 'validateFormControl',
                }],
            },
            multi: true,
        },
        {
            provide: FORM_COMPONENTS_MAP,
            useValue: {
                import: async () => import('./textfield.component'),
                types: ['email'],
                // eslint-disable-next-line @typescript-eslint/unbound-method
                validators: [Validators.email],
            },
            multi: true,
        },
        {
            provide: CUSTOM_FORM_CONTROL_VALIDATOR_TOKEN,
            useFactory: textFieldValidatorFactory,
            multi: true,
        },
    ]);
